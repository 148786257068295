import React from 'react';
import { inject, observer } from 'mobx-react';
import { NavLink } from 'react-router-dom';
import classNames from 'classnames';
import { withStyles } from '@material-ui/core/styles';
import Drawer from '@material-ui/core/Drawer';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import HomeIcon from '@material-ui/icons/Home';

import styles from './styles';
import { navigationItems } from '../../Config/routes';
import navItem from './navitem';

class Navigator extends React.Component {
  render() {
    const { classes, appState, ...other } = this.props;
    const isAdmin = appState && appState.userData && appState.userData.stRole === 'admin';
    const navItems = navigationItems(isAdmin);
    return (
      <Drawer variant="permanent" {...other}>
        <List disablePadding>
          <NavLink activeClassName="active-nav" exact={true} to="/home">
            <ListItem className={classNames(classes.firebase, classes.item, classes.itemCategory)}>
              StreamerToys
        </ListItem>
          </NavLink>
          <NavLink activeClassName={classNames(classes.itemActiveItem)} exact={true} to="/home">
            <ListItem className={classNames(classes.item, classes.itemCategory, classes.itemActionable)}>
              <ListItemIcon>
                <HomeIcon />
              </ListItemIcon>
              <ListItemText classes={{ primary: classes.itemPrimary }}>
                Home
          </ListItemText>
            </ListItem>
          </NavLink>
          {navItems.map(item => navItem(classes)(item))}
        </List>
      </Drawer>
    );
  }
};

export default inject('appState', 'routing')(withStyles(styles)(observer(Navigator)));
