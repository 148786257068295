import { decorate, observable, action } from 'mobx';

class SettingsStore {
  formLoaded = false;

  formError = null;
  dirtyForm = false;
  savingForm = false;

  oauthToken = '';
  username = '';
  channelName = '';

  tempOAuthToken = '';
  tempUsername = '';
  tempChannelName = '';

  statusChecked = false;
  botStatus = false;
  botStatusUpdated = true;

  async loadForm() {
    try {
      const result = await fetch('/api/settings', { credentials: "include" });
      if (result.status === 200) {
        const data = await result.json();
        if (data.oauthToken) {
          this.oauthToken = data.oauthToken;
          this.tempOAuthToken = data.oauthToken;
        }
        if (data.username) {
          this.username = data.username;
          this.tempUsername = data.username;
        }
        if (data.channelName) {
          this.channelName = data.channelName;
          this.tempChannelName = data.channelName;
        }
        this.formLoaded = true;
      } else {
        throw new Error('Error getting form data');
      }
    } catch (err) {
      this.formLoaded = true;
      this.formError = err.message;
    }
  }

  async loadBotStatus() {
    try {
      this.botStatusUpdated = false;
      const result = await fetch(`/api/settings/bot/`, {
        credentials: 'include',
        headers: {
          'Content-Type': 'application/json; charset=utf-8'
        },
      });

      if (result.status === 200) {
        const data = await result.json();
        this.botStatus = data.active;
        this.botStatusUpdated = true;
      } else {
        throw new Error('Error getting bot data');
      }
    } catch (e) {
      this.botStatusUpdated = true;
    }
  }

  async saveForm() {
    try {
      this.savingForm = true;
      const result = await fetch('/api/settings', {
        credentials: 'include',
        method: 'post',
        body: JSON.stringify({
          oauthToken: this.oauthToken,
          username: this.username,
          channelName: this.channelName,
        }),
        headers: {
          'Content-Type': 'application/json; charset=utf-8'
        },
      });

      if (result.status === 200) {
        this.tempOAuthToken = this.oauthToken;
        this.tempUsername = this.username;
        this.tempChannelName = this.channelName;

        this.savingForm = false;
        this.dirtyForm = false;
        this.modalOpen = true;
      } else {
        throw new Error('Error getting form data');
      }

      this.savingForm = false;
    } catch (e) {
      this.savingForm = false;
    }
  }

  async toggleBot() {
    try {
      this.botStatusUpdated = false;
      const status = (!this.botStatus ? "start" : "stop");
      const result = await fetch(`/api/settings/bot/${status}`, {
        credentials: 'include',
        method: 'post',
        headers: {
          'Content-Type': 'application/json; charset=utf-8'
        },
      });

      if (result.status === 200) {
        this.botStatus = !this.botStatus;
        this.botStatusUpdated = true;
        this.modalOpen = true;
      } else {
        throw new Error('Error getting form data');
      }
    } catch (e) {
      this.botStatusUpdated = true;
    }
  }

  resetForm() {
    this.oauthToken = this.tempOAuthToken;
    this.username = this.tempUsername;
    this.channelName = this.tempChannelName;
  }

  setOauthToken = token => {
    this.dirtyForm = true;
    this.oauthToken = token;
  }

  setUsername = name => {
    this.dirtyForm = true;
    this.username = name;
  }

  setChannelName = name => {
    this.dirtyForm = true;
    this.channelName = name;
  }
}


export default decorate(SettingsStore, {
  formLoaded: observable,
  formError: observable,
  dirtyForm: observable,
  savingForm: observable,
  modalOpen: observable,
  loadForm: action,
  resetForm: action,
  statusChecked: observable,
  setOauthToken: action,
  setUsername: action,
  setChannelName: action,
  channelName: observable,
  oauthToken: observable,
  username: observable,
  botStatus: observable,
  botStatusUpdated: observable,
  loadBotStatus: action,
});
