import React from 'react';
import ReactDOM from 'react-dom';
import { createBrowserHistory } from 'history';
import { Provider } from 'mobx-react';
import { RouterStore, syncHistoryWithStore } from 'mobx-react-router';
import { Router } from 'react-router';

import AppState from './Stores/appstate';
import SpinnerStore from './Stores/spinner';
import SettingsStore from './Stores/settings';
import AdminStore from './Stores/admin';

import App from './App';

import './index.css';

const browserHistory = createBrowserHistory();
const routingStore = new RouterStore();

const stores = {
  routing: routingStore,
  appState: new AppState(),
  spinnerStore: new SpinnerStore(),
  settingsStore: new SettingsStore(),
  adminStore: new AdminStore(),
};

const history = syncHistoryWithStore(browserHistory, routingStore);

ReactDOM.render(
  <Provider {...stores}>
    <Router history={history}>
      <App />
    </Router>
  </Provider>,
  document.getElementById('root')
);