const styles = theme => ({
  categoryHeader: {
    paddingTop: 16,
    paddingBottom: 16,
    // boxShadow: '0 -1px 0 #404854 inset',
  },
  categoryHeaderPrimary: {
    color: theme.palette.common.white,
    fontWeight: 600,
    fontSize: '1.2rem',
  },
  item: {
    paddingTop: 4,
    paddingBottom: 4,
    color: 'rgba(255, 255, 255, 0.7)',
  },
  itemCategory: {
    // backgroundColor: '#232f3e',
    // boxShadow: '0 -1px 0 #404854 inset',
    paddingTop: 16,
    paddingBottom: 16,
  },
  firebase: {
    fontSize: 24,
    fontFamily: theme.typography.fontFamily,
    color: theme.palette.common.white,
  },
  itemActionable: {
    '&:hover': {
      backgroundColor: 'rgba(255, 255, 255, 0.08)',
    },
  },
  itemActiveItem: {
    '& > li': {
      color: '#4fc3f7',
      backgroundColor: '#232f3e',
    },
  },
  itemPrimary: {
    color: 'inherit',
    fontSize: theme.typography.fontSize,
    '&$textDense': {
      fontSize: theme.typography.fontSize,
    },
  },
  textDense: {},
  divider: {
    marginTop: theme.spacing.unit * 2,
  },
});

export default styles;
