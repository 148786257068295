import HomePage from './Home';
import LandingPage from './Landing';
import LoginPage from './Login';
import SpinnerPage from './Spinner';
import ClipperPage from './Clipper';
import AdminPage from './Admin';
import SettingsPage from './Settings';
import LoadingPage from './Loading';

export default {
  HomePage,
  LandingPage,
  LoadingPage,
  LoginPage,
  SettingsPage,
  SpinnerPage,
  ClipperPage,
  AdminPage,
};
