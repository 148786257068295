const styles = theme => ({
  paper: {
    maxWidth: 936,
    margin: 'auto',
    overflow: 'hidden',
  },
  searchBar: {
    borderBottom: '1px solid rgba(0, 0, 0, 0.12)',
  },
  searchInput: {
    fontSize: theme.typography.fontSize,
  },
  block: {
    display: 'block',
  },
  addUser: {
    marginRight: theme.spacing.unit,
  },
  contentWrapper: {
    margin: '40px 16px',
  },
  twitchButton: {
    '&:hover': {
      background: '#7d5abe',
      color: '#fff'
    },
    fontSize: '16px',
    fontWeight: '400',
    padding: '1.032rem 3.073rem',
    textDecoration: 'none',
    display: 'inline-block',
    position: 'relative',
    textShadow: 'none',
    background: '#6441A4',
    border: '1px solid #6441A4',
    borderRadius: '3px',
    color: '#fff',
    marginRight: '5px',
    fontFamily: 'graphik, sans-serif',
  },
});

export default styles;
