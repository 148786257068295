import React, { Component } from 'react';
import { Switch, withRouter } from 'react-router-dom';
import { inject, observer } from 'mobx-react';

import Pages from './Pages';

import { PublicRoute, PrivateRoute, CheckRoute } from './RouteTypes';

import './App.css';


class App extends Component {

  componentDidMount() {
    this.props.appState.check();
  }

  render() {
    if (this.props.appState.checked) {
      return (
        <React.Fragment>
          <Switch>
            <PublicRoute exact path="/" component={Pages.LandingPage} loggedIn={this.props.appState.authenticated} {...this.props} />
            <CheckRoute exact path="/login" component={Pages.LoginPage} loggedIn={this.props.appState.authenticated} {...this.props} />
            <PrivateRoute exact path="/home" component={Pages.HomePage} loggedIn={this.props.appState.authenticated} {...this.props} />
            <PrivateRoute exact path="/spinner" component={Pages.SpinnerPage} loggedIn={this.props.appState.authenticated} {...this.props} />
            <PrivateRoute exact path="/clipper" component={Pages.ClipperPage} loggedIn={this.props.appState.authenticated} {...this.props} />
            <PrivateRoute exact path="/settings" component={Pages.SettingsPage} loggedIn={this.props.appState.authenticated} {...this.props} />
            <PrivateRoute exact path="/admin" component={Pages.AdminPage} loggedIn={this.props.appState.authenticated} {...this.props} />
          </Switch>
        </React.Fragment>
      );
    } else {
      return (<Pages.LoadingPage />)
    }
  }
};

export default inject('appState')(withRouter(observer(App)));
