import React from 'react';
import { MuiThemeProvider, withStyles } from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';

import { theme, styles } from '../../Styles/Global';
import Header from '../../Components/Header/plain';

class LoginPage extends React.Component {
  state = {
    mobileOpen: false,
  };

  render() {
    const { classes } = this.props;

    return (
      <MuiThemeProvider theme={theme}>
        <div className={classes.root}>
          <CssBaseline />
          <div className={classes.appContent}>
            <Header title="StreamerToys" />
            <main className={classes.mainContent}>
            </main>
          </div>
        </div>
      </MuiThemeProvider>
    );
  }
}

export default withStyles(styles)(LoginPage);