const lightColor = 'rgba(255, 255, 255, 0.7)';

const styles = theme => ({
  secondaryBar: {
    zIndex: 0,
  },
  menuButton: {
    marginLeft: -theme.spacing.unit,
  },
  iconButtonAvatar: {
    padding: 4,
  },
  link: {
    textDecoration: 'none',
    color: lightColor,
    '&:hover': {
      color: theme.palette.common.white,
    },
  },
  button: {
    borderColor: lightColor,
  },
  headerTitle: {
    fontSize: '1.3rem',
    fontWeight: 500,
    color: '#fff'
  },
  loginHeader: {
    color: '#fff'
  },
  username: {
    fontWeight: 600
  },
  noPad: {
    padding: 0
  },
  menuItem: {
    padding: '11px 16px',
  }
});

export default styles;
