import { decorate, observable, action, computed } from 'mobx';

class AppState {
  authenticated = false;
  socket = null;
  userData = null;
  userLoaded = false;
  checked = false;

  async fetchUser() {
    try {
      const result = await fetch('/auth/user', { credentials: "include" });
      if (result.status === 200) {
        this.userData = await result.json();
        this.authenticated = true;
        this.userLoaded = true;
        this.checked = true;
        return this.userData;
      } else {
        this.authenticated = false;
        this.checked = true;
        return false;
      }
    } catch (err) {
      this.authenticated = false;
      this.checked = true;
      console.log('Error fetching authorized user.');
      return false;
    }
  }

  async check() {
    const result = await fetch('/auth/check', { credentials: "include" });
    if (result.status === 200) {
      this.authenticated = true;
      this.checked = true;
    } else {
      this.authenticated = false;
      this.checked = true;
    }
  }

  get user() {
    if (this.authenticated && this.userLoaded) {
      return this.userData;
    }

    return this.fetchUser();
  }
}

export default decorate(AppState, {
  socket: observable,
  authenticated: observable,
  userData: observable,
  user: computed,
  userLoaded: observable,
  setAuthenticted: action,
  check: action,
  checked: observable
});
