import { inject, observer } from 'mobx-react';
import React from 'react';
import { MuiThemeProvider, withStyles } from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';
import AppBar from '@material-ui/core/AppBar';
import Hidden from '@material-ui/core/Hidden';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import Tooltip from '@material-ui/core/Tooltip';
import TextField from '@material-ui/core/TextField';
import classNames from 'classnames';
import CopyText from 'react-copy-text';

import styles from './styles';

import { theme, drawerWidth } from '../../Styles/Global';
import Navigator from '../../Components/Navigator';
import Header from '../../Components/Header';

class Clipper extends React.Component {
  state = {
    mobileOpen: false,
    textToCopy: '',
    streamer: '',
  };

  doCopy(url) {
    this.setState({ textToCopy: url }, () => {
      this.setState({ textToCopy: url })
    });
  }

  handleDrawerToggle = () => {
    this.setState(state => ({ mobileOpen: !state.mobileOpen }));
  };

  render() {
    const { classes } = this.props;
    const stPrivateToken = this.props.appState.userData ? this.props.appState.userData.stPrivateToken : '';

    return (
      <MuiThemeProvider theme={theme}>
        <div className={classes.root}>
          <CssBaseline />
          <nav className={classes.drawer}>
            <Hidden smUp implementation="js">
              <Navigator
                PaperProps={{ style: { width: drawerWidth } }}
                variant="temporary"
                open={this.state.mobileOpen}
                onClose={this.handleDrawerToggle}
              />
            </Hidden>
            <Hidden xsDown implementation="css">
              <Navigator PaperProps={{ style: { width: drawerWidth } }} />
            </Hidden>
          </nav>
          <div className={classes.appContent}>
            <Header title="Clipper" onDrawerToggle={this.handleDrawerToggle} />
            <main className={classes.mainContent}>
              <Paper className={classes.paper}>
                <AppBar className={classes.searchBar} position="static" color="default" elevation={0}>
                  <Toolbar>
                    <Grid container spacing={16} alignItems="center">
                      Clipper URL
                    </Grid>
                  </Toolbar>
                </AppBar>
                <div className={classes.contentWrapper}>
                  <TextField
                    key='clip_streamer'
                    id='clip-streamer'
                    label='Username to Clip'
                    fullWidth
                    className={classes.textField}
                    value={this.state.streamer}
                    onChange={(e) => { this.setState({ streamer: e.target.value }) }}
                    margin="normal"
                    variant="outlined"
                  />
                  <br /><br />
                  {this.state.streamer &&
                    (<Tooltip title="Click to copy!" classes={{ tooltip: classes.tooltip }}>
                      <Typography
                        noWrap={true}
                        className={classNames(classes.copier)}
                        onClick={(e) => this.doCopy(`${process.env.REACT_APP_DOMAIN}/actions/clipper/clip/${stPrivateToken}?username=${this.state.streamer}`)}>
                        {`${process.env.REACT_APP_DOMAIN}/actions/clipper/clip/${stPrivateToken}?username=${this.state.streamer}`}
                      </Typography>
                    </Tooltip>)}
                  <CopyText text={this.state.textToCopy} />
                </div>
              </Paper>
            </main>
          </div>
        </div>
      </MuiThemeProvider>
    );
  }
}

export default inject('appState')(withStyles(styles)(observer(Clipper)));