import green from '@material-ui/core/colors/green';

const styles = theme => ({
  paper: {
    maxWidth: 936,
    margin: 'auto',
    overflow: 'hidden',
    marginBottom: '40px',
  },
  searchBar: {
    borderBottom: '1px solid rgba(0, 0, 0, 0.12)',
  },
  block: {
    display: 'block',
  },
  addUser: {
    marginRight: theme.spacing.unit,
  },
  contentWrapper: {
    margin: '40px 16px',
  },
  button: {
    margin: theme.spacing.unit,
  },
  leftIcon: {
    marginRight: theme.spacing.unit,
  },
  rightIcon: {
    marginLeft: theme.spacing.unit,
  },
  iconSmall: {
    fontSize: 20,
  },
  loading: {
    opacity: 0.5,
  },
  saveInformation: {
    padding: '40px 16px',
    fontSize: 20,
  },
  success: {
    backgroundColor: green[600],
  },
  icon: {
    fontSize: 20,
  },
  iconVariant: {
    opacity: 0.9,
    marginRight: theme.spacing.unit,
  },
  message: {
    display: 'flex',
    alignItems: 'center',
  },
  blurText: {
    textShadow: '0 0 5px hsla(0,0%,0%,.5)',
    color: 'transparent',
    userSelect: 'none',
  },
  widgetUrl: {
    cursor: 'pointer',
    fontSize: '14px',
    '&:active': {
      color: '#cdcdcd',
    }
  },
  tooltip: {
    fontSize: '14px !important'
  }
});

export default styles;
