import React from 'react';
import { NavLink } from 'react-router-dom';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import classNames from 'classnames';

export default classes => ({ id, icon, path }) => {
  const Icon = icon;
  return (
    <NavLink key={`nav_item_${id}`} to={path} exact={true} activeClassName={classNames(classes.itemActiveItem)}>
      <ListItem
        className={classNames(
          classes.item,
          classes.itemActionable,
          classes.itemCategory
        )}
      >
        <ListItemIcon><Icon /></ListItemIcon>
        <ListItemText
          classes={{
            primary: classes.itemPrimary,
            textDense: classes.textDense,
          }}
        >
          {id}
        </ListItemText>
      </ListItem>
    </NavLink>
  );
};
