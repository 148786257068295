import { decorate, observable, action } from 'mobx';

const emptySegments = [
  { id: 1, name: 'Green', text: '' },
  { id: 2, name: 'Crow', text: '' },
  { id: 3, name: 'Shazie', text: '' },
  { id: 4, name: 'Blue', text: '' },
  { id: 5, name: 'Red', text: '' },
  { id: 6, name: 'Black', text: '' },
  { id: 7, name: 'Purple', text: '' },
  { id: 8, name: 'Spin Again', text: '' },
  { id: 9, name: 'Orange', text: '' }
];

class SpinnerStore {
  announce = false;
  formLoaded = false;
  formError = null;
  dirtyForm = false;
  savingForm = false;
  modalOpen = false;

  segments = [...emptySegments];

  tempSegments = [...emptySegments];

  tempAnnounce = false;

  setSegment = (id, text) => {
    this.dirtyForm = true;
    this.segments[parseInt(id)].text = text;
  }

  setAnnounce = (val) => {
    this.dirtyForm = true;
    this.announce = val;
  }

  async loadForm() {
    try {
      const result = await fetch('/api/spinner', { credentials: "include" });
      if (result.status === 200) {
        const data = await result.json();
        if (data.segments.length) {
          this.segments.replace(data.segments);
          this.tempSegments.replace(data.segments);
        }
        this.announce = data.announce;
        this.tempAnnounce = data.announce;
        this.formLoaded = true;
      } else {
        throw new Error('Error gettting form data');
      }
    } catch (err) {
      this.formLoaded = true;
      this.formError = err.message;
    }
  }

  async saveForm(target) {
    try {
      this.savingForm = true;
      const result = await fetch('/api/spinner', {
        credentials: 'include',
        method: 'post',
        body: JSON.stringify({ segments: this.segments, announce: this.announce }),
        headers: {
          'Content-Type': 'application/json; charset=utf-8'
        },
      });

      if (result.status === 200) {
        this.tempSegments.replace(this.segments);
        this.tempAnnounce = this.announce;
        this.savingForm = false;
        this.dirtyForm = false;
        this.modalOpen = true;
      } else {
        throw new Error('Error gettting form data');
      }
    } catch (err) {
      this.savingForm = false;
      this.formLoaded = true;
      this.formError = err.message;
      this.modalOpen = false;
    }
  }

  resetForm() {
    this.savingForm = false;
    this.segments.replace([...emptySegments]);
    this.announce = this.tempAnnounce;
    this.formLoaded = false;
    this.dirtyForm = false;
    this.modalOpen = false;
  }
}


export default decorate(SpinnerStore, {
  dirtyForm: observable,
  announce: observable,
  segments: observable,
  tempSegments: observable,
  tempAnnounce: observable,
  formLoaded: observable,
  formError: observable,
  setSegment: action,
  saveForm: action,
  loadForm: action,
  resetForm: action,
  setAnnounce: action,
  savingForm: observable,
  anchorElement: observable,
  modalOpen: observable,
});
