import { decorate, observable, action } from 'mobx';

class AdminStore {
  usersLoaded = false;
  users = [];
  loadError = null;

  async loadUsers() {
    try {
      const result = await fetch('/api/admin/users', { credentials: "include" });
      if (result.status === 200) {
        const data = await result.json();
        this.users = data;
        this.usersLoaded = true;
      } else {
        throw new Error('Error getting user data');
      }
    } catch (err) {
      this.usersLoaded = true;
      this.loadError = err.message;
    }
  }

  async toggleBot(id, active) {
    try {
      const status = (!active ? "start" : "stop");
      const result = await fetch(`/api/admin/bot/${status}/${id}`, {
        credentials: 'include',
        method: 'post',
        headers: {
          'Content-Type': 'application/json; charset=utf-8'
        },
      });

      if (result.status === 200) {
        this.users.forEach((user) => {
          if (user.stid === id) {
            user.bot.active = !user.bot.active;
          }
        })
      } else {
        throw new Error('Error getting form data');
      }
    } catch (e) {
      // this.botStatusUpdated = true;
    }
  }
}

export default decorate(AdminStore, {
  usersLoaded: observable,
  users: observable,
  loadError: observable,
  loadUsers: action,
  toggleBot: action,
});