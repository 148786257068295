import React from "react";
import { Route, Redirect } from "react-router-dom";

export default ({ component: Component, ...rest }) => (
  <Route
    {...rest}
    exact
    render={props => {
      if (rest.loggedIn) {
        return (
          <Redirect to={{ pathname: "/home", state: { from: props.location } }} />
        )
      } else {
        return (<Component {...props} />)
      }
    }
    }
  />
);
