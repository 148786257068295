import React from 'react';
import { inject, observer } from 'mobx-react';
import { MuiThemeProvider, withStyles } from '@material-ui/core/styles';
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import CssBaseline from '@material-ui/core/CssBaseline';
import Hidden from '@material-ui/core/Hidden';

import { theme, styles, drawerWidth } from '../../Styles/Global';
import Navigator from '../../Components/Navigator';
import Header from '../../Components/Header';
import Panel from './panel';

class Admin extends React.Component {
  state = {
    mobileOpen: false,
  };

  componentDidMount = () => {
    this.props.adminStore.loadUsers();
  }

  handleDrawerToggle = () => {
    this.setState(state => ({ mobileOpen: !state.mobileOpen }));
  };

  render() {
    const { classes } = this.props;

    const users = this.props.adminStore.users.map(user => (
      <ExpansionPanel key={user.stid}>
        <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
          <Typography className={classes.heading}>{user.display_name}</Typography>
        </ExpansionPanelSummary>
        <ExpansionPanelDetails>
          <Panel active={user.bot.active} id={user.stid} />
        </ExpansionPanelDetails>
      </ExpansionPanel>
    ));

    return (
      <MuiThemeProvider theme={theme}>
        <div className={classes.root}>
          <CssBaseline />
          <nav className={classes.drawer}>
            <Hidden smUp implementation="js">
              <Navigator
                PaperProps={{ style: { width: drawerWidth } }}
                variant="temporary"
                open={this.state.mobileOpen}
                onClose={this.handleDrawerToggle}
              />
            </Hidden>
            <Hidden xsDown implementation="css">
              <Navigator PaperProps={{ style: { width: drawerWidth } }} />
            </Hidden>
          </nav>
          <div className={classes.appContent}>
            <Header title="Admin" onDrawerToggle={this.handleDrawerToggle} />
            <main className={classes.mainContent}>
              {users}
            </main>
          </div>
        </div>
      </MuiThemeProvider>
    );
  }
}

export default inject('adminStore')(withStyles(styles)(observer(Admin)));