import React from 'react';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';
import { withStyles } from '@material-ui/core/styles';
import { Icon } from 'semantic-ui-react';

import 'semantic-ui-css/semantic.min.css';
import styles from './styles';

function LoginPanel(props) {
  const { classes } = props;

  return (
    <Paper className={classes.paper}>
      <div className={classes.contentWrapper}>
        <Typography color="textSecondary" align="center">
          <a href="/auth/twitch" className={classes.twitchButton}><Icon name="twitch" size="large" />Connect with Twitch</a>
        </Typography>
      </div>
    </Paper>
  );
}

export default withStyles(styles)(LoginPanel);
