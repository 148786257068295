import Label from '@material-ui/icons/Label';
import SettingsIcon from '@material-ui/icons/Settings';
import TuneIcon from '@material-ui/icons/Tune';

const navigationFields = [
  'id',
  'icon',
  'path'
];

const config = [
  {
    id: 'Home',
    path: '/home',
    private: true,
    navigation: false,
  },
  {
    id: 'Spinner',
    path: '/spinner',
    private: true,
    navigation: true,
    icon: Label,
  },
  {
    id: 'Clipper',
    path: '/clipper',
    admin: true,
    private: true,
    navigation: true,
    icon: Label,
  },
  {
    id: 'Settings',
    path: '/settings',
    private: true,
    navigation: true,
    icon: SettingsIcon
  },
  {
    id: 'Admin',
    path: '/admin',
    private: true,
    navigation: true,
    admin: true,
    icon: TuneIcon
  },
];

const hideAdmin = (admin, item) => {
  if (admin) {
    return true;
  }

  return item.admin !== true;
};

const isNavigation = item => item.navigation;

const navigationItems = (admin = false) => config.filter(isNavigation).filter(item => hideAdmin(admin, item)).map(item => {
  return navigationFields.reduce((acc, curr) => {
    return {
      ...acc,
      [curr]: item[curr]
    }
  }, {});
});

export {
  navigationItems
};
