import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import classNames from 'classnames';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import Switch from '@material-ui/core/Switch';
import Snackbar from '@material-ui/core/Snackbar';
import SnackbarContent from '@material-ui/core/SnackbarContent';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';

import { withStyles } from '@material-ui/core/styles';

import SaveIcon from '@material-ui/icons/Save';

import styles from './styles';

class SettingsForm extends Component {
  handleClose = () => {
    this.props.settingsStore.anchorElement = null;
  };

  componentDidMount() {
    this.props.settingsStore.loadForm();
    this.props.settingsStore.loadBotStatus();
  }

  componentWillUnmount() {
    this.props.settingsStore.resetForm();
  }

  doCopy(url) {
    this.setState({ textToCopy: '' }, () => {
      this.setState({ textToCopy: url })
    });
  }

  render() {
    const { classes } = this.props;
    const loadingClass = (this.props.settingsStore.formLoaded && this.props.appState.userData) ? '' : classes.loading;
    const open = Boolean(this.props.settingsStore.anchorElement);

    return (
      <Paper className={classNames(classes.paper, loadingClass)}>
        <AppBar className={classes.searchBar} position="static" color="default" elevation={0}>
          <Toolbar>
            <Grid container spacing={16} alignItems="flex-end">
              <Grid item xs>
                <Switch
                  disabled={!this.props.settingsStore.formLoaded || !this.props.settingsStore.botStatusUpdated}
                  checked={this.props.settingsStore.botStatus}
                  onChange={() => { this.props.settingsStore.toggleBot() }}
                  value="botStatus"
                  color="primary"
                  className={classNames(classes.botSwitch)}
                />
                Bot Status: {this.props.settingsStore.botStatus ? "On" : "Off"}
              </Grid>
              <Grid item>
                <Button
                  disabled={!this.props.settingsStore.formLoaded || !this.props.settingsStore.dirtyForm || this.props.settingsStore.savingForm}
                  variant="contained"
                  size="large"
                  color="primary"
                  aria-owns={open ? 'simple-popper' : undefined}
                  aria-haspopup="true"
                  className={classNames(classes.button)}
                  onClick={(e) => this.props.settingsStore.saveForm(e.currentTarget)}>
                  <SaveIcon className={classNames(classes.leftIcon, classes.iconSmall)} />
                  Save
              </Button>
                <Snackbar
                  anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'right',
                  }}
                  open={this.props.settingsStore.modalOpen}
                  autoHideDuration={6000}
                  onClose={() => { this.props.settingsStore.modalOpen = false }}>
                  <SnackbarContent
                    className={classNames(classes.success, classes.message)}
                    aria-describedby="client-snackbar"
                    message={
                      <span id="client-snackbar" className={classes.message}>
                        <CheckCircleIcon className={classNames(classes.icon, classes.iconVariant)} />
                        Settings saved.
                          </span>
                    }
                    action={[
                      <IconButton
                        key="close"
                        aria-label="Close"
                        color="inherit"
                        className={classes.close}
                        onClick={() => { this.props.settingsStore.modalOpen = false }}
                      >
                        <CloseIcon className={classes.icon} />
                      </IconButton>,
                    ]}
                  />
                </Snackbar>
              </Grid>
            </Grid>
          </Toolbar>
        </AppBar>
        <div className={classes.contentWrapper}>
          <a href="https://twitchapps.com/tmi/" target="_blank" rel="noopener noreferrer">Get the OAuth Token from here</a>
          <br /><br />
          <TextField
            disabled={!this.props.settingsStore.formLoaded}
            key='sf_oauth'
            id='sf-oauth'
            label='Bot OAuth Token'
            fullWidth
            className={classes.textField}
            value={this.props.settingsStore.oauthToken}
            onChange={(e) => { this.props.settingsStore.setOauthToken(e.target.value) }}
            margin="normal"
            variant="outlined"
          />
          <TextField
            disabled={!this.props.settingsStore.formLoaded}
            key='sf_username'
            id='sf-username'
            label='Bot Username'
            fullWidth
            className={classes.textField}
            value={this.props.settingsStore.username}
            onChange={(e) => { this.props.settingsStore.setUsername(e.target.value) }}
            margin="normal"
            variant="outlined"
          />
          <TextField
            disabled={!this.props.settingsStore.formLoaded}
            key='sf_cname'
            id='sf-cname'
            label='Twitch Channel Name'
            fullWidth
            className={classes.textField}
            value={this.props.settingsStore.channelName}
            onChange={(e) => { this.props.settingsStore.setChannelName(e.target.value) }}
            margin="normal"
            variant="outlined"
          />
        </div>
      </Paper >
    );
  }
}

export default inject('appState', 'settingsStore')(withStyles(styles)(observer(SettingsForm)));
