import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import classNames from 'classnames';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import Switch from '@material-ui/core/Switch';
import Snackbar from '@material-ui/core/Snackbar';
import SnackbarContent from '@material-ui/core/SnackbarContent';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import CopyText from 'react-copy-text';
import Tooltip from '@material-ui/core/Tooltip';

import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';

import { withStyles } from '@material-ui/core/styles';

import SaveIcon from '@material-ui/icons/Save';

import styles from './styles';

class Content extends Component {
  state = {
    textToCopy: ''
  };

  handleClose = () => {
    this.props.spinnerStore.anchorElement = null;
  };

  componentDidMount() {
    this.props.spinnerStore.loadForm();
  }

  componentWillUnmount() {
    this.props.spinnerStore.resetForm();
  }

  doCopy(url) {
    this.setState({ textToCopy: url }, () => {
      this.setState({ textToCopy: url })
    });
  }

  render() {
    const { classes } = this.props;
    const stPublicToken = this.props.appState.userData ? this.props.appState.userData.stPublicToken : '';
    const stPrivateToken = this.props.appState.userData ? this.props.appState.userData.stPrivateToken : '';
    const loadingClass = (this.props.spinnerStore.formLoaded && this.props.appState.userData) ? '' : classes.loading;
    const open = Boolean(this.props.spinnerStore.anchorElement);

    return (
      <Paper className={classNames(classes.paper, loadingClass)}>
        <AppBar className={classes.searchBar} position="static" color="default" elevation={0}>
          <Toolbar>
            <Grid container spacing={16} alignItems="flex-end">
              <Grid item xs />
              <Grid item>
                <Button
                  disabled={!this.props.spinnerStore.formLoaded || !this.props.spinnerStore.dirtyForm || this.props.spinnerStore.savingForm}
                  variant="contained"
                  size="large"
                  color="primary"
                  aria-owns={open ? 'simple-popper' : undefined}
                  aria-haspopup="true"
                  onClick={(e) => this.props.spinnerStore.saveForm(e.currentTarget)}>
                  <SaveIcon className={classNames(classes.leftIcon, classes.iconSmall)} />
                  Save
              </Button>
                <Snackbar
                  anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'right',
                  }}
                  open={this.props.spinnerStore.modalOpen}
                  autoHideDuration={6000}
                  onClose={() => { this.props.spinnerStore.modalOpen = false }}>
                  <SnackbarContent
                    className={classNames(classes.success, classes.message)}
                    aria-describedby="client-snackbar"
                    message={
                      <span id="client-snackbar" className={classes.message}>
                        <CheckCircleIcon className={classNames(classes.icon, classes.iconVariant)} />
                        Spinner saved.
                          </span>
                    }
                    action={[
                      <IconButton
                        key="close"
                        aria-label="Close"
                        color="inherit"
                        className={classes.close}
                        onClick={() => { this.props.spinnerStore.modalOpen = false }}
                      >
                        <CloseIcon className={classes.icon} />
                      </IconButton>,
                    ]}
                  />
                </Snackbar>
              </Grid>
            </Grid>
          </Toolbar>
        </AppBar>
        <div className={classes.contentWrapper}>
          <ExpansionPanel>
            <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
              <Typography variant='h6' className={classes.heading}>Widget URL</Typography>
            </ExpansionPanelSummary>
            <ExpansionPanelDetails>
              <Tooltip title="Click to copy!" classes={{ tooltip: classes.tooltip }}>
                <Typography
                  noWrap={true}
                  className={classNames(classes.widgetUrl)}
                  onClick={(e) => this.doCopy(`${process.env.REACT_APP_DOMAIN}/widgets/spinner/v1/?key=${stPublicToken}`)}>
                  {`${process.env.REACT_APP_DOMAIN}/widgets/spinner/v1/?key=${stPublicToken}`}
                </Typography>
              </Tooltip>
              <CopyText text={this.state.textToCopy} />
            </ExpansionPanelDetails>
          </ExpansionPanel>
          <ExpansionPanel>
            <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
              <Typography className={classes.heading} variant='h6'>Trigger URL</Typography>
            </ExpansionPanelSummary>
            <ExpansionPanelDetails>
              <Tooltip title="Click to copy!" classes={{ tooltip: classes.tooltip }}>
                <Typography
                  noWrap={true}
                  className={classNames(classes.widgetUrl)}
                  onClick={(e) => this.doCopy(`${process.env.REACT_APP_DOMAIN}/actions/spinner/spin/${stPrivateToken}`)}>
                  {`${process.env.REACT_APP_DOMAIN}/actions/spinner/spin/${stPrivateToken}`}
                </Typography>
              </Tooltip>
            </ExpansionPanelDetails>
          </ExpansionPanel>
          <br />
          <Typography color="inherit" variant="h6">
            Do you want the result announced?
        </Typography>
          <Switch
            disabled={!this.props.spinnerStore.formLoaded}
            checked={this.props.spinnerStore.announce}
            onChange={() => { this.props.spinnerStore.setAnnounce(!this.props.spinnerStore.announce) }}
            value="checkedB"
            color="primary"
          />
          <Typography color="inherit" variant="h6">
            Enter what you want your bot to say!
        </Typography>
          {this.props.spinnerStore.segments.map((segment, index) => {
            return (<TextField
              disabled={!this.props.spinnerStore.formLoaded}
              key={`seg_${index}`}
              id={`segment-${index}`}
              label={`${index + 1} - ${segment.name}`}
              fullWidth
              className={classes.textField}
              value={segment.text}
              onChange={(e) => { this.props.spinnerStore.setSegment(index, e.target.value) }}
              margin="normal"
              variant="outlined"
            />)
          })}
        </div>
      </Paper >
    );
  }
}

export default inject('appState', 'spinnerStore')(withStyles(styles)(observer(Content)));
