import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import Switch from '@material-ui/core/Switch';

class Panel extends Component {
  render() {
    return (
      <Switch
        checked={this.props.active}
        onChange={() => { this.props.adminStore.toggleBot(this.props.id, this.props.active) }}
        value="botStatus"
        color="primary"
      />
    );
  }
}

export default inject('adminStore')(observer(Panel));
